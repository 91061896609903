import { createApp } from 'vue';
import { createPinia } from 'pinia';
import MegaMenuButton from '../components/MegaMenuButton.vue';
import MegaMenu from '../components/MegaMenu.vue';

const pinia = createPinia();

const app = createApp(MegaMenuButton);
app.use(pinia);
app.mount('#megamenuButton');

const megaMenu = createApp(MegaMenu);
megaMenu.mount('#megamenu');
