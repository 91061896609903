<script setup>
import { ref } from 'vue';
import { assetURL, baseURL } from '../utils/url';
import { useMainMenuStore } from '../store/MainMenuStore';

const props = defineProps(['locations']);

const store = useMainMenuStore();
const { showMegaMenu } = store;
</script>

<template>
    <section>
        <button
            @click="showMegaMenu"
            class="bg-none tw-border-0 nav__mobile-button"
            title="Menu"
        >
            <picture>
                <source
                    type="image/webp"
                    :srcset="`${assetURL}images/icons/burger.webp`"
                />
                <img
                    :src="`${assetURL}images/icons/burger.png`"
                    alt=""
                    width=""
                    height=""
                    class="tw-max-w-full lg:tw-px-3 nav__mobile-icon"
                />
            </picture>
            <p
                class="tw-text-[8px] tw-text-center tw-mb-0 tw-hidden lg:tw-block tw-leading-tight tw-text-black tw-mt-[0px]"
            >
                <b>MENU</b>
            </p>
        </button>
    </section>
</template>
