<script setup>
import { ref, computed } from 'vue';
import { sanitizeUrl } from '@braintree/sanitize-url';
import { assetURL, baseURL } from '../utils/url';

const props = defineProps(['property', 'propertyType']);
const { property, propertyType } = props;

const showPricesCount = ref(2);

const visiblePriceItems = computed(() =>
    property.prices.slice(0, showPricesCount.value)
);

function showMore() {
    showPricesCount.value += 100;
}
</script>

<template>
    <div
        class="shadow tw-p-6 tw-bg-white tw-relative tw-grid tw-grid-cols-1 lg:tw-grid-cols-300px-1fr tw-gap-6 tw-mb-8 py-32px-xxl"
    >
        <div class="tw-relative">
            <picture>
                <source type="image/webp" :srcset="property.webpImage" />
                <img
                    :src="property.image"
                    :alt="`${property.name} thumbnail`"
                    width=""
                    height=""
                    class="tw-max-w-full tw-w-full lg:tw-object-cover lg:tw-object-top tw-aspect-[4/3]"
                />
            </picture>
            <div
                v-if="property.status === 'Under Construction'"
                class="tw-absolute tw-top-0 tw-right-0 tw-bg-grey1 tw-px-4 tw-py-2 tw-flex lg:tw-hidden tw-items-center tw-content-center tw-gap-4 lg:tw-gap-2 lg:tw-text-[14px] xl:tw-text-[16px] xl:tw-gap-4"
            >
                <img
                    :src="`${baseURL}/images/icons/under-construction-green.svg`"
                    class="tw-w-6 lg:tw-w-4 xl:tw-w-6"
                />
                <p class="tw-darkGreen tw-mb-0 tw-text-right">
                    Under construction. <br />
                    <!-- <span v-if="property.readBy" class="tw-font-bold">{{
                        property.readyBy
                    }}</span> -->
                </p>
            </div>
            <div
                v-if="property.status === 'Pre-selling'"
                class="tw-absolute tw-top-0 tw-right-0 tw-bg-grey1 tw-px-4 tw-py-2 tw-flex lg:tw-hidden tw-items-center tw-content-center tw-gap-4 lg:tw-gap-2 lg:tw-text-[14px] xl:tw-text-[16px] xl:tw-gap-4"
            >
                <img
                    :src="`${baseURL}/images/icons/planning-icon-green.svg`"
                    class="tw-w-6 lg:tw-w-4 xl:tw-w-6"
                />
                <p class="tw-darkGreen tw-mb-0 tw-text-right">
                    Pre-selling. <br />
                    <!-- <span v-if="property.readBy" class="tw-font-bold">{{
                        property.readyBy
                    }}</span> -->
                </p>
            </div>
            <div
                v-if="property.status === 'Ready for Occupancy'"
                class="tw-absolute tw-top-0 tw-right-0 tw-bg-yellow2 tw-px-4 tw-py-2 tw-flex lg:tw-hidden tw-items-center tw-content-center tw-gap-4 lg:tw-gap-2 lg:tw-text-[14px] xl:tw-text-[16px] xl:tw-gap-4"
            >
                <img
                    :src="`${baseURL}/images/icons/ready-for-occupancy-green.svg`"
                    class="tw-w-6 lg:tw-w-4 xl:tw-w-6"
                />
                <p class="tw-darkGreen tw-mb-0 tw-text-right">
                    Ready for occupancy.
                </p>
            </div>
        </div>

        <div>
            <h3
                class="tw-text-darkGreen tw-text-[24px] tw-font-bold lg:tw-leading-none quick-search__property-name"
            >
                {{ property.name }}
            </h3>
            <p
                class="tw-text-[10px] tw-uppercase tw-text-darkTeal tw-bg-grey1 tw-inline-block tw-py-2 tw-px-3 tw-mb-0"
            >
                {{ property.location }}
            </p>

            <div
                class="tw-grid xxl:tw-grid-cols-1fr-min-content tw-mt-6 tw-mb-2 lg:tw-mb-0 mt-24px-xxl"
            >
                <div class="xxl:tw-flex xxl:tw-flex-col xxl:tw-justify-end">
                    <div
                        class="tw-flex tw-items-center tw-content-center tw-gap-2 tw-mb-6 mb-24px-xxl"
                    >
                        <img
                            :src="`${baseURL}/images/icons/location-pin.svg`"
                        />
                        <p class="tw-mb-0 tw-text-darkTeal tw-text-[14px]">
                            {{ property.landmark }}
                        </p>
                    </div>
                    <!-- <div class="tw-grid lg:tw-grid-cols-2 tw-gap-2.5">
                        <p v-if="propertyType !== 'Offices'" class="xxl:tw-mb-0 xxl:tw-leading-tight">
                            <span
                                class="tw-font-bold tw-text-[20px] tw-text-darkGreen"
                                >PHP {{ property.startingPrice }}</span
                            ><br />
                            <span class="tw-text-darkTeal tw-text-[14px]"
                                >Starting price <template v-if="property.unitType != 'undefined'">for {{ property.unitType }}</template></span
                            >
                        </p>
                        <p v-else>
                            Please contact us for pricing details
                        </p>
                        <p class="xxl:tw-mb-0 xxl:tw-leading-tight">
                            <span
                                class="tw-font-bold tw-text-[20px] tw-text-darkGreen"
                                >{{ property.floorArea }}</span
                            ><br />
                            <span class="tw-text-darkTeal tw-text-[14px]"
                                >Floor area range <template v-if="property.unitType != 'undefined'">for {{ property.unitType }}</template></span
                            >
                        </p>
                    </div> -->
                    <div
                        class="lg:tw-pr-4 tw-mb-4"
                        v-if="property.prices && propertyType !== 'Offices'"
                    >
                        <span
                            class="tw-font-bold tw-text-[20px] tw-text-darkGreen tw-mt-4 tw-mb-2 tw-block"
                            >Unit Types</span
                        >
                        <div
                            class="tw-grid tw-py-1 tw-grid-cols-2 tw-border-b-0 tw-border-l-0 tw-border-r-0 tw-border-t tw-border-grey1 tw-border-solid"
                            v-for="(price, index) in visiblePriceItems"
                            :key="index"
                        >
                            <div>{{ price.name }}</div>
                            <div>
                                {{ parseInt(price.size_min) }}sqm -
                                {{ parseInt(price.size_max) }}sqm
                            </div>
                        </div>
                        <button
                            class="bg-transparent tw-font-bold tw-border-0 tw-px-0 tw-text-darkGreen tw-uppercase"
                            v-if="
                                visiblePriceItems.length <
                                property.prices.length
                            "
                            @click="showMore"
                        >
                            Load More <span class="fa fa-chevron-down"></span>
                        </button>
                    </div>
                </div>

                <div
                    class="tw-grid tw-gap-3 lg:tw-flex lg:tw-max-w-[350px] xxl:tw-flex-col xxl:tw-justify-end"
                >
                    <a
                        class="fs-12px lg:tw-tracking-widest tw-bg-darkGreen hover:tw-bg-darkGreen-950 tw-text-white hover:tw-text-white tw-no-underline tw-text-base tw-transition-all tw-duration-300 tw-py-2 lg:tw-py-2 tw-px-4 lg:tw-px-6 tw-text-center tw-inline-block lg:tw-w-60 tw-border tw-border-solid tw-border-darkGreen"
                        :href="sanitizeUrl(property.learnMoreLink)"
                        >LEARN MORE</a
                    >

                    <a
                        class="fs-12px lg:tw-tracking-widest tw-bg-transparent hover:tw-bg-darkGreen-950 tw-text-darkGreen hover:tw-text-white tw-no-underline tw-text-base tw-transition-all tw-duration-300 tw-py-2 lg:tw-py-2 tw-px-4 lg:tw-px-6 tw-text-center tw-border tw-border-solid tw-border-darkGreen tw-inline-block lg:tw-w-60"
                        :href="sanitizeUrl(property.inquireLink)"
                        >INQUIRE</a
                    >
                </div>
            </div>
        </div>
        <div
            v-if="property.status === 'Under Construction'"
            class="tw-absolute tw-top-0 tw-right-0 tw-bg-grey1 tw-px-4 tw-py-2 tw-hidden lg:tw-flex tw-items-center tw-content-center tw-gap-4 lg:tw-gap-2 lg:tw-text-[14px] xl:tw-text-[16px] xl:tw-gap-4"
        >
            <img
                :src="`${baseURL}/images/icons/under-construction-green.svg`"
                class="tw-w-6 lg:tw-w-4 xl:tw-w-6"
            />
            <p class="tw-darkGreen tw-mb-0 tw-text-right">
                Under construction. <br />
                <!-- Ready by
                <span class="tw-font-bold">{{ property.readyBy }}</span> -->
            </p>
        </div>
        <div
            v-if="property.status === 'Pre-selling'"
            class="tw-absolute tw-top-0 tw-right-0 tw-bg-grey1 tw-px-4 tw-py-2 tw-hidden lg:tw-flex tw-items-center tw-content-center tw-gap-4 lg:tw-gap-2 lg:tw-text-[14px] xl:tw-text-[16px] xl:tw-gap-4"
        >
                <img
                    :src="`${baseURL}/images/icons/planning-icon-green.svg`"
                    class="tw-w-6 lg:tw-w-4 xl:tw-w-6"
                />
            <p class="tw-darkGreen tw-mb-0 tw-text-right">
                Pre-selling. <br />
                <!-- Ready by
                <span class="tw-font-bold">{{ property.readyBy }}</span> -->
            </p>
        </div>
        <div
            v-if="property.status === 'Ready for Occupancy'"
            class="tw-absolute tw-top-0 tw-right-0 tw-bg-yellow2 tw-px-4 tw-py-2 tw-hidden lg:tw-flex tw-items-center tw-content-center tw-gap-4 lg:tw-gap-2 lg:tw-text-[14px] xl:tw-text-[16px] xl:tw-gap-4"
        >
            <img
                :src="`${baseURL}/images/icons/ready-for-occupancy-green.svg`"
                class="tw-w-6 lg:tw-w-4 xl:tw-w-6"
            />
            <p class="tw-darkGreen tw-mb-0 tw-text-right">
                Ready for occupancy.
            </p>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@media (min-width: 992px) {
    .quick-search__property-name {
        width: 19vw;
    }
}

@media (min-width: 1200px) {
    .quick-search__property-name {
        width: 25vw;
    }
}
</style>
