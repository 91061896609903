const advisoryNotice = document.getElementById('advisory-notice');
const advisoryNoticeHasBeenClosed = localStorage.getItem(
    'advisoryNoticeClosed'
);

if (advisoryNotice && !advisoryNoticeHasBeenClosed) {
    advisoryNotice.classList.remove('tw-hidden');

    document
        .getElementById('close-advisory-notice')
        .addEventListener('click', () => {
            advisoryNotice.classList.add('tw-opacity-0');
            localStorage.setItem('advisoryNoticeClosed', 'true');
            setTimeout(() => {
                advisoryNotice.remove();
            }, 220);
        });
}
