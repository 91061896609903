import './bootstrap';
import '../third-party/jquery/jquery-3.6.0.min.js';
import canUseWebp from './utils/canUseWebp';
// eslint-disable-next-line no-unused-vars
import * as bootstrap from '../third-party/bootstrap-5.2.0-dist/js/bootstrap.bundle.js'; // hack so that bootstrap variable will be usable
// eslint-disable-next-line import/no-unresolved
import './utils/advisoryNotice';
import './utils/cookiePolicy';
import './utils/screenHeight';
import './controllers/NavbarController';
import './controllers/EstatesController';
import './controllers/PropertiesController';
import './controllers/QuickSearchController';
import './controllers/SearchController';
import './controllers/ScheduleController';

const webpEnabled = canUseWebp();
if (webpEnabled) {
    document.body.classList.add('webp-enabled');
}
