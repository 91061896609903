<script setup>
import { defineProps, ref, computed, defineExpose } from 'vue';

const props = defineProps({
    data: Object,
});

// console.log(props.data);
props.data.currentStep--;

const data = ref(props.data);

const cssStyle = computed(() => ({
    '--active-color': data.value.activeColor,
    '--passive-color': data.value.passiveColor,
}));
const nextStep = () => {
    if (data.value.currentStep < data.value.steps.length) {
        data.value.currentStep++;
    }
    console.log();
};
const prevStep = () => {
    if (data.value.currentStep > 0) {
        data.value.currentStep--;
    }
};
defineExpose({
    nextStep,
    prevStep,
});
</script>

<script>
export default {
    data() {
        return {
            selectedDetailId: [],
            labels: [
                {
                    name: 'propertyType',
                    text: 'Property type',
                    options: ['type 1', 'type 2', 'type 3'],
                },
                {
                    name: 'category',
                    text: 'Category',
                    options: ['Condominium', 'Category 1', 'Category 2'],
                },
                {
                    name: 'estate',
                    text: 'Estate',
                    options: ['Capitol Commons', 'Estate 2', 'Estate 3'],
                },
                {
                    name: 'propertyName',
                    text: 'Property Name',
                    options: ['Maven at Capitol Commons', 'Name 2', 'Name 3'],
                },
            ],
        };
    },
};
</script>

<template>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div
                    class="fs-32px fs-40px-xl fw-regular tw-text-darkGreen mb-24px lh-1"
                >
                    Want to schedule a visit? Tell us more.
                </div>
                <div class="steps-list" :style="cssStyle">
                    <ul>
                        <li
                            v-for="(step, index) in data.steps"
                            :key="index"
                            :class="
                                (index == data.currentStep ? 'step-active' : '',
                                index < data.currentStep ? 'step-done' : '',
                                index == 0 && index == data.currentStep
                                    ? 'step-done__advance'
                                    : '')
                            "
                        >
                            <div class="step-count">
                                {{ step }}
                            </div>
                        </li>
                    </ul>
                </div>
                <div
                    class="form-content mt-30px"
                    v-for="(step, index) in data.steps"
                >
                    <div v-if="data.currentStep == index">
                        <span
                            class="d-none d-lg-block steps-count fs-16px tw-text-darkGreen text-uppercase fw-semibold mt-64px-lg"
                        >
                            Step {{ step }} of {{ data.steps.length }}
                        </span>
                        <div v-if="step == 1" id="formStep1">
                            <div class="fs-24px tw-text-darkGreen mb-30px">
                                What property are you interested in?
                            </div>
                            <form id="formStep1">
                                <div class="row">
                                    <div
                                        class="col-12 col-lg-6"
                                        v-for="(label, index) in labels"
                                    >
                                        <div class="form-group">
                                            <label
                                                class="fs-16px fw-medium tw-text-black"
                                                >{{ label.text }}</label
                                            >
                                            <select :name="label.name">
                                                <option
                                                    v-for="option in label.options"
                                                    :value="option"
                                                >
                                                    {{ option }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
