<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Controller, Navigation } from 'swiper';
import { sanitizeUrl } from '@braintree/sanitize-url';

import { storeToRefs } from 'pinia';
import AOS from 'aos';
import { assetURL, baseURL } from '../utils/url';
import TryQuickSearch from './TryQuickSearch.vue';
import { useMainMenuStore } from '../store/MainMenuStore';

const store = useMainMenuStore();
const { showQuickSearch } = store;
const { properties, propSpaceBetween, propSlideView, isDesktop } =
    storeToRefs(store);
const emit = defineEmits(['closeMenu', 'backToMainMenu']);
const modules = [Controller, Navigation];
const navButtons = {
    prevEl: '.prev-btn',
    nextEl: '.next-btn',
};

function closeFullMenu() {
    emit('closeMenu');
}

function backToMainMenu() {
    emit('backToMainMenu');
}
</script>

<script>
export default {
    mounted() {
        AOS.init();
    },
};
</script>

<template>
    <section
        class="tw-fixed lg:tw-relative tw-top-0 tw-left-0 tw-py-8 lg:tw-pt-0 tw-px-[20px] full-height tw-w-full lg:tw-pl-12 lg:tw-pr-0 tw-overflow-y-auto tw-z-10 tw-bg-grey1 tw-max-h-screen"
    >
        <section
            class="lg:tw-mr-[30px] tw-mb-8 tw-grid tw-grid-cols-inner-megamenu-grid lg:tw-grid-cols-1 tw-content-end tw-items-end tw-justify-center tw-gap-x-2.5"
        >
            <button
                @click="backToMainMenu"
                class="bg-none tw-border-0 lg:tw-hidden"
            >
                <i class="fa fa-chevron-left" aria-hidden="true"></i>
            </button>
            <h2
                class="tw-text-darkGreen tw-text-[24px] lg:tw-text-[40px] tw-font-light lg:tw-mt-14 tw-mb-0 lg:tw-mb-4 lg:tw-hidden"
            >
                Featured Properties
            </h2>
            <div class="lg:tw-self-center lg:tw-hidden">
                <button
                    @click="showQuickSearch"
                    class="bg-none tw-border-0"
                    title="Open Quick Search"
                >
                    <picture>
                        <source
                            type="image/webp"
                            :srcset="`${assetURL}images/icons/search.webp`"
                        />
                        <img
                            :src="`${assetURL}images/icons/search.png`"
                            alt="Search icon"
                            width=""
                            height=""
                            class="tw-max-w-full lg:tw-px-4"
                        />
                    </picture>
                    <p
                        class="tw-text-xs tw-text-center tw-mb-0 tw-hidden lg:tw-block"
                    >
                        <b>QUICK SEARCH</b>
                    </p>
                </button>
            </div>
            <div class="tw-text-right">
                <button
                    @click="closeFullMenu"
                    title="Close mega menu"
                    class="bg-none tw-border-0 lg:tw-absolute lg:tw-right-2.5 lg:tw-top-2.5 tw-text-darkGreen tw-text-2xl tw-leading-none"
                >
                    <i class="fa fa-close" aria-hidden="true"></i>
                </button>
            </div>
        </section>

        <section
            class="tw-mt-14 tw-mb-8 tw-block lg:tw-grid tw-grid-cols-2 lg:tw-pr-12"
        >
            <h2
                class="tw-text-darkGreen tw-text-[0px] lg:tw-text-[40px] tw-font-light tw-invisible lg:tw-visible"
            >
                Featured Properties
            </h2>
            <div class="tw-text-right">
                <a
                    class="tw-text-semibold tw-text-darkGreen hover:tw-text-darkGreen tw-no-underline hover:tw-underline tw-tracking-widest"
                    :href="sanitizeUrl(`${baseURL}/properties`)"
                    >ALL PROPERTIES
                    <i
                        class="fa fa-chevron-right tw-pl-2"
                        aria-hidden="true"
                    ></i
                ></a>
            </div>
        </section>

        <section class="tw-relative">
            <div v-if="isDesktop">
                <button
                    class="prev-btn tw-text-4xl tw-absolute tw-top-1/2 tw-z-10 -tw-left-8 md:-tw-left-12 tw-bg-white tw-border-none tw-text-accentGreen tw-w-12 tw-h-12"
                >
                    &lt;
                </button>

                <button
                    class="next-btn tw-text-4xl tw-absolute tw-top-1/2 tw-z-10 tw-right-0 tw-bg-white tw-border-none tw-text-accentGreen tw-w-12 tw-h-12"
                >
                    &gt;
                </button>
            </div>

            <swiper
                v-if="isDesktop"
                :modules="modules"
                :navigation="navButtons"
                :slidesPerView="propSlideView"
                :spaceBetween="propSpaceBetween"
            >
                <swiper-slide
                    v-for="(property, index) in properties"
                    :key="property.id"
                >
                    <a
                        :href="sanitizeUrl(property.link)"
                        class="tw-no-underline"
                    >
                        <div
                            class="tw-relative"
                            data-aos="fade-down"
                            :data-aos-delay="index * 300"
                            data-aos-once="true"
                            data-aos-duration="300"
                        >
                            <figure
                                class="tw-mb-0 megamenu__property-gradient tw-h-[420px] xxl:tw-h-[640px]"
                            >
                                <picture>
                                    <source
                                        type="image/webp"
                                        :srcset="property.webpImage"
                                    />
                                    <img
                                        :src="property.webpImage"
                                        :alt="`${property.name} thumbnail`"
                                        width=""
                                        height=""
                                        class="tw-w-full tw-h-full tw-object-cover tw-object-top"
                                    />
                                </picture>
                            </figure>
                            <div
                                class="tw-absolute tw-bottom-4 tw-left-0 megamenu__property-content"
                            >
                                <h3
                                    class="tw-text-2xl tw-font-extrabold tw-text-white tw-mb-0 text-no-underline tw-mx-4"
                                >
                                    {{ property.name }}
                                </h3>
                                <p
                                    class="tw-font-medium tw-text-midGreen tw-uppercase tw-mx-4 tw-tracking-widest"
                                >
                                    {{ property.estate }}
                                </p>
                                <div
                                    class="starting-price-alt"
                                    v-if="!property.startingPrice"
                                />
                                <p
                                    class="menu__property-price fs-12px mb-0px tw-bg-white tw-inline-block tw-p-4 tw-font-regular tw-text-darkGreen tw-border-l-0 tw-border-y tw-border-r tw-border-solid tw-border-[#064333] tw-max-w-80pct lg:tw-max-w-full"
                                    v-if="property.startingPrice"
                                >
                                    Starts at
                                    <span
                                        class="tw-font-extrabold tw-text-lg fs-16px"
                                        >PHP
                                        {{
                                            (
                                                property.startingPrice / 1000000
                                            ).toPrecision(2)
                                        }}M</span
                                    >
                                </p>
                            </div>
                        </div>
                    </a>
                </swiper-slide>
            </swiper>

            <section
                v-else
                class="tw-grid tw-grid-cols-2 lg:tw-grid-cols-4 tw-gap-2.5 md:tw-gap-10"
            >
                <div
                    v-for="(property, index) in properties"
                    :key="property.id"
                    class="tw-relative megamenu__property-mobile"
                    data-aos="fade-down"
                    :data-aos-delay="index * 300"
                    data-aos-once="true"
                    data-aos-duration="300"
                >
                    <a
                        :href="sanitizeUrl(property.link)"
                        class="tw-no-underline"
                    >
                        <figure class="tw-mb-0 megamenu__property-gradient">
                            <picture>
                                <source
                                    type="image/webp"
                                    :srcset="property.webpImage"
                                />
                                <img
                                    :src="property.webpImage"
                                    :alt="`${property.name} thumbnail`"
                                    width=""
                                    height=""
                                    class="tw-w-full tw-h-full tw-object-cover tw-object-center ratio ratio-4x3"
                                />
                            </picture>
                        </figure>
                        <div
                            class="tw-absolute tw-bottom-4 tw-left-0 megamenu__property-content"
                        >
                            <h3
                                class="tw-text-2xl tw-font-extrabold tw-text-white tw-mb-0 text-no-underline tw-mx-4"
                            >
                                {{ property.name }}
                            </h3>
                            <p
                                class="tw-font-medium tw-text-midGreen tw-uppercase tw-mx-4 tw-tracking-widest"
                            >
                                {{ property.estate }}
                            </p>
                            <p
                                class="menu__property-price fs-12px tw-bg-white tw-inline-block py-8px px-15px tw-font-regular tw-text-darkGreen tw-border-l-0 tw-border-y tw-border-r tw-border-solid tw-border-[#064333] lg:tw-max-w-full mb-0px"
                                v-if="property.startingPrice"
                            >
                                Starts at
                                <span
                                    class="tw-font-extrabold tw-text-lg fs-16px"
                                    >PHP
                                    {{
                                        (
                                            property.startingPrice / 1000000
                                        ).toPrecision(2)
                                    }}M</span
                                >
                            </p>
                        </div>
                    </a>
                </div>
            </section>
        </section>

        <TryQuickSearch />
    </section>
</template>

<style lang="scss">
.megamenu__property-gradient {
    height: 75vw;
}
.menu__property-price {
    span {
        line-height: 1;
    }
}
.megamenu__property-mobile {
    figure {
        display: none;
    }
    &.aos-animate {
        figure {
            display: block;
        }
    }
}

@media (min-width: 992px) {
    .megamenu__property-gradient {
        height: 38vw;
        max-height: 640px;
    }
}
</style>
