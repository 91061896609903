<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { sanitizeUrl } from '@braintree/sanitize-url';
import { assetURL, baseURL } from '../utils/url';
import MegaMenuButton from './MegaMenuButton.vue';
import QuickSearchProperty from './QuickSearchProperty.vue';
import QuickSearchLoading from './QuickSearchLoading.vue';
import { useMainMenuStore } from '../store/MainMenuStore';
import axios from 'axios';

const props = defineProps([
    'initial',
    // 'locations',
    // 'priceRanges',
    // 'sizeRanges',
    // 'sizeRangesOffices',
    // 'bedroomRanges',
]);
const store = useMainMenuStore();
const { showMegaMenu, showQuickSearch, hideQuickSearch, cookiePolicyAccepted } =
    store;
const { isShowingQuickSearch } = storeToRefs(store);
const isShowingMainMenu = ref(true);
const isLoading = ref(false);

// values to pass to quick search API
const checkedLocations = ref([]);
const checkedPriceRanges = ref([]);
const checkedSizeRanges = ref([]);
const checkedBedroomRanges = ref([]);
const checkedPropertyType = ref('Residences');
const checkedStatuses = ref([]);

/** computed props * */
// Price Range
const priceMin = computed(() => {
    const joined = checkedPriceRanges.value.join('-');
    const splitted = joined.split('-');
    return Math.min(...splitted) >= 0 ? Math.min(...splitted) : null;
});
const priceMax = computed(() => {
    const joined = checkedPriceRanges.value.join('-');
    const splitted = joined.split('-');
    return Math.max(...splitted) ? Math.max(...splitted) : null;
});

// Size Range
const sizeMin = computed(() => {
    const joined = checkedSizeRanges.value.join('-');
    const splitted = joined.split('-');
    return Math.min(...splitted) >= 0 ? Math.min(...splitted) : null;
});
const sizeMax = computed(() => {
    const joined = checkedSizeRanges.value.join('-');
    const splitted = joined.split('-');
    return Math.max(...splitted) >= 0 ? Math.max(...splitted) : null;
});

// Bedroom Range
const bedroomMin = computed(() => {
    const joined = checkedBedroomRanges.value.join('-');
    const splitted = joined.split('-');
    return Math.min(...splitted) >= 0 ? Math.min(...splitted) : null;
});
const bedroomMax = computed(() => {
    const joined = checkedBedroomRanges.value.join('-');
    const splitted = joined.split('-');
    return Math.max(...splitted) >= 0 ? Math.max(...splitted) : null;
});

// filters shown in page
let locations = ref({});
let priceRanges = ref({});
let sizeRanges = ref({});
let sizeRangesOffices = ref({});
let bedroomRanges = ref({});

onMounted(() => {
    axios.get('/quick-search/locations').then(({ data }) => {
        locations.value = data;
    });
    axios.get('/quick-search/price-range').then(({ data }) => {
        priceRanges.value = data;
    });
    axios.get('/quick-search/size-range-residence').then(({ data }) => {
        sizeRanges.value = data;
    });
    axios.get('/quick-search/size-range-office').then(({ data }) => {
        sizeRangesOffices.value = data;
    });
    axios.get('/quick-search/bedroom-range').then(({ data }) => {
        bedroomRanges.value = data;
    });
});

const matchingProperties = ref([]);
const otherProperties = ref([]);

function closeFullMenu() {
    hideQuickSearch();
    props.initial = 0;
    isShowingMainMenu.value = true;
}

function showFilters() {
    document.getElementById('filters').classList.remove('tw-hidden');
    document.getElementById('filtersButton').style.display = 'none';
    document.getElementById('quickSearchResults').style.display = 'none';
    endDemo();
}

function hideFilters() {
    document.getElementById('filters').classList.add('tw-hidden');
    document.getElementById('filtersButton').style.display = 'block';
    document.getElementById('quickSearchResults').style.display = 'block';
}

function endDemo() {
    if (document.getElementById('quickSearchDemo')) {
        document.getElementById('quickSearchDemo').style.display = 'none';
    }
    document.getElementById('quickSearchResults').style.display = 'block';
}

function showInitialScreen() {
    document.getElementById('quickSearchResults').style.display = 'block';
    document.getElementById('quickSearchDemo').style.display = 'none';
    checkedLocations.value = [];
    checkedPriceRanges.value = [];
    checkedSizeRanges.value = [];
    checkedBedroomRanges.value = [];
    checkedPropertyType.value = '';
    checkedStatuses.value = [];
    // fetchResultsFromAPI('initial');
}

let timer; // how many milliseconds before triggering quick search
let timerTimeoutFunction; // store interval function here so that it can be cleared if quick search is being triggered
function getQuickSearchResults() {
    clearTimeout(timerTimeoutFunction);
    timer = 1000;

    // timerTimeoutFunction = setTimeout(() => {
        // clearTimeout(timerTimeoutFunction);
        fetchResultsFromAPI('search');
    // }, timer);
}

async function fetchResultsFromAPI(type) {
    try {
        endDemo();
        isLoading.value = true;
        let response = '';
        // if (type == 'search') {
            response = await axios.get(`${baseURL}/api/quick-search`, {
                params: {
                    type: checkedPropertyType.value,
                    location: checkedLocations.value,
                    price_min: priceMin.value,
                    price_max: priceMax.value,
                    size_min: sizeMin.value,
                    size_max: sizeMax.value,
                    bedroom_min: bedroomMin.value,
                    bedroom_max: bedroomMax.value,
                    status: checkedStatuses.value,
                },
            });
        // } 
        // else {
        //     response = await axios.get(`${baseURL}/api/initial-results`);
        // }

        isLoading.value = false;

        if (response.data.status) {
            const { properties, relatedProperties } = response.data.data;
            matchingProperties.value = [];
            properties.forEach((element) => {
                let startingPrice = 0;
                let finalFloorArea = 0;
                let finalUnitType = 'None';
                // filter by price min & price max
                let { prices } = element;
                if (priceMin.value && priceMax.value) {
                    prices = prices.filter(
                        (size) =>
                            (size.price_min >= priceMin.value &&
                                size.price_min <= priceMax.value) ||
                            (size.price_max >= priceMin.value &&
                                size.price_max <= priceMax.value)
                    );
                }

                if (
                    sizeMin.value >= 0 &&
                    sizeMax.value >= 0 &&
                    sizeMin.value !== sizeMax.value
                ) {
                    prices = prices.filter(
                        (size) =>
                            (size.size_min >= sizeMin.value &&
                                size.size_min <= sizeMax.value) ||
                            (size.size_max >= sizeMin.value &&
                                size.size_max <= sizeMax.value)
                    );
                }

                // filter by bedroom
                if (
                    bedroomMin.value >= 0 &&
                    bedroomMax.value >= 0 &&
                    bedroomMin.value !== bedroomMax.value
                ) {
                    prices = prices.filter(
                        (size) =>
                            size.num_bedroom >= bedroomMin.value &&
                            size.num_bedroom <= bedroomMax.value
                    );
                }

                prices.sort((a, b) => a.price_min - b.price_min);
                if (prices[0] == null) {
                    startingPrice = 0;
                    finalFloorArea = '0 sqm';
                    finalUnitType = 'None';
                } else {
                    startingPrice = prices[0].price_min;
                    finalFloorArea = `${Math.ceil(
                        prices[0].size_min
                    )} - ${Math.ceil(prices[0].size_max)} sqm`;
                    finalUnitType = `${prices[0].name}`;
                }

                matchingProperties.value.push({
                    name: element.name,
                    image: `${baseURL}/images/quicksearch/2br-unit.jpg`,
                    webpImage: element.thumbnail_image_landscape,
                    location: element.location.name,
                    landmark: element.landmark,
                    prices,
                    startingPrice: `${parseFloat(
                        (startingPrice / 1000000).toPrecision(2)
                    )}M`,
                    floorArea: finalFloorArea,
                    unitType: finalUnitType,
                    learnMoreLink: element.url,
                    inquireLink:
                        `${assetURL}contact-us/inquiry/` +
                        `?type=${element.type}&estate=${element.estate_id}&property=${element.id}`,
                    status: element.status,
                    readyBy: element.ready_by,
                });
            });

            otherProperties.value = [];
            relatedProperties.forEach((element) => {
                let startingPrice = 0;
                let finalFloorArea = 0;
                let finalUnitType = 'None';

                if (element.prices[0] == null) {
                    startingPrice = 0;
                    finalFloorArea = '0 sqm';
                    finalUnitType = 'None';
                } else {
                    startingPrice = element.prices[0].price_min;
                    finalFloorArea = `${Math.ceil(
                        element.prices[0].size_min
                    )} - ${Math.ceil(element.prices[0].size_max)} sqm`;
                    finalUnitType = `${element.prices[0].name}`;
                }

                otherProperties.value.push({
                    name: element.name,
                    image: `${baseURL}/images/quicksearch/2br-unit.jpg`,
                    webpImage: element.thumbnail_image_landscape,
                    location: element.location.name,
                    landmark: element.landmark,
                    startingPrice: `${parseFloat(
                        (startingPrice / 1000000).toPrecision(2)
                    )}M`,
                    floorArea: finalFloorArea,
                    unitType: finalUnitType,
                    learnMoreLink: element.url,
                    inquireLink:
                        `${assetURL}contact-us/inquiry/` +
                        `?type=${element.type}&estate=${element.estate_id}&property=${element.id}`,
                    status: element.status,
                    readyBy: element.ready_by,
                });
            });
        }
    } catch (error) {
        isLoading.value = false;
        console.error(error);
        matchingProperties.value = [];
    }
}
watch(isShowingQuickSearch, async (newQuickSearch, oldQuickSearch) => {
    if (newQuickSearch) {
        clearTimeout(timerTimeoutFunction);
        timer = 1000;

        timerTimeoutFunction = setTimeout(() => {
            clearTimeout(timerTimeoutFunction);
            fetchResultsFromAPI('initial');
        }, timer);
        // fetchResultsFromAPI('initial');
    }
});
</script>

<template>
    <section>
        <Transition name="mega-menu">
            <div
                v-if="isShowingQuickSearch"
                class="tw-z-10 tw-fixed tw-top-0 tw-left-0 tw-bg-white tw-w-full tw-h-full lg:tw-grid lg:tw-grid-cols-megamenu-desktop tw-overflow-auto lg:tw-overflow-hidden"
            >
                <section
                    v-if="isShowingMainMenu"
                    class="tw-grid tw-grid-cols-1 tw-gap-y-2.5 tw-auto-rows-min tw-py-8 tw-px-[20px] lg:tw-px-8 tw-bg-grey1 tw-overflow-auto"
                >
                    <section
                        class="tw-relative tw-h-[120px] lg:tw-h-auto lg:tw-mr-[30px] lg:tw-mr-0 tw-grid tw-grid-cols-navbar-grid lg:tw-grid-cols-1 lg:tw-content-center tw-justify-center tw-gap-x-2.5"
                    >
                        <div class="tw-hidden lg:tw-block">
                            <button
                                @click="showMegaMenu"
                                class="tw-text-left bg-none tw-border-0 tw-text-darkGreen mb-32px"
                            >
                                <i
                                    class="fa fa-chevron-left tw-pr-4 fs-10px"
                                ></i
                                ><span class="tw-text-[14px] fw-semibold"
                                    >Main menu</span
                                >
                            </button>
                        </div>

                        <div
                            class="tw-mb-[0px] tw-absolute tw-bottom-[0px] tw-w-full lg:tw-mb-[30px] lg:tw-relative"
                        >
                            <form
                                :action="`${baseURL}/site-search`"
                                class="tw-relative"
                            >
                                <input
                                    class="menu__search-input tw-pl-10 tw-pr-2 tw-w-full tw-py-2 tw-border tw-border-solid tw-border-grey2"
                                    type="search"
                                    name="keyword"
                                    placeholder="Search this website"
                                    required
                                />
                                <button
                                    class="bg-none tw-border-0 tw-absolute tw-left-2 tw-top-2"
                                    type="submit"
                                ></button>
                            </form>
                        </div>

                        <h2 class="tw-text-[24px] tw-text-darkGreen mb-0px">
                            Quick Search
                            <picture>
                                <source
                                    type="image/webp"
                                    :srcset="`${assetURL}/images/icons/quick-search-darkgreen.webp`" />
                                <img
                                    :src="`${assetURL}/images/icons/quick-search-darkgreen.png`"
                                    alt="Search icon"
                                    width=""
                                    height=""
                                    class="tw-max-w-full tw-h-[24px] tw-pl-4"
                            /></picture>
                        </h2>
                        <div class="lg:tw-self-center lg:tw-hidden">
                            <MegaMenuButton />
                        </div>
                        <div class="tw-text-right lg:tw-hidden">
                            <button
                                @click="closeFullMenu"
                                title="Close mega menu"
                                class="bg-none tw-border-0 tw-text-darkGreen tw-text-2xl"
                            >
                                <i class="fa fa-close" aria-hidden="true"></i>
                            </button>
                        </div>
                    </section>
                    <button
                        @click="showFilters"
                        id="filtersButton"
                        class="lg:tw-hidden bg-none tw-border tw-border-darkGreen tw-text-darkGreen tw-py-2 tw-my-2"
                    >
                        <img :src="`${baseURL}/images/icons/filter.svg`" />
                        FILTERS
                    </button>

                    <div id="filters" class="tw-hidden lg:tw-block">
                        <h3
                            class="tw-font-medium tw-text-black tw-text-base tw-mt-8"
                        >
                            Property type
                        </h3>

                        <div class="tw-grid tw-grid-cols-2">
                            <label class="quick-search__radio">
                                <input
                                    @change="getQuickSearchResults"
                                    name="property_type"
                                    type="radio"
                                    value="Residences"
                                    v-model="checkedPropertyType"
                                />
                                <div class="tw-text-[14px]">
                                    <span
                                        class="property-type__residential-icon"
                                    ></span
                                    >Residential
                                </div>
                            </label>

                            <label class="quick-search__radio">
                                <input
                                    @change="getQuickSearchResults"
                                    name="property_type"
                                    type="radio"
                                    value="Offices"
                                    v-model="checkedPropertyType"
                                />
                                <div class="tw-text-[14px]">
                                    <span
                                        class="property-type__office-icon"
                                    ></span
                                    >Office
                                </div>
                            </label>
                        </div>

                        <h3
                            class="tw-font-medium tw-text-black tw-text-base tw-mt-6"
                        >
                            Location
                        </h3>

                        <div>
                            <label
                                v-for="location in locations"
                                class="quick-search__checkbox"
                            >
                                <input
                                    :value="location.id"
                                    type="checkbox"
                                    v-model="checkedLocations"
                                    @change="getQuickSearchResults"
                                />
                                <div>
                                    {{ location.name }}
                                    <i class="fa-solid fa-check"></i>
                                </div>
                            </label>
                        </div>

                        <!-- <template v-if="checkedPropertyType != 'Offices'">
                            <h3
                                class="tw-font-medium tw-text-black tw-text-base tw-mt-6"
                            >
                                Your budget (PHP)
                            </h3>

                            <div>
                                <label
                                    v-for="priceRange in priceRanges"
                                    class="quick-search__checkbox"
                                >
                                    <input
                                        :value="priceRange.value"
                                        type="checkbox"
                                        v-model="checkedPriceRanges"
                                        @change="getQuickSearchResults"
                                    />
                                    <div>{{ priceRange.name }}</div>
                                </label>
                            </div>
                        </template> -->

                        <template v-if="checkedPropertyType != 'Offices'">
                            <h3
                                class="tw-font-medium tw-text-black tw-text-base tw-mt-6"
                            >
                                Size Range (SQM)
                            </h3>

                            <div>
                                <label
                                    v-for="sizeRange in sizeRanges"
                                    class="quick-search__checkbox"
                                >
                                    <input
                                        :value="sizeRange.value"
                                        type="checkbox"
                                        v-model="checkedSizeRanges"
                                        @change="getQuickSearchResults"
                                    />
                                    <div>{{ sizeRange.name }}</div>
                                </label>
                            </div>
                        </template>

                        <!-- <template v-if="checkedPropertyType == 'Offices'">
                            <h3
                                class="tw-font-medium tw-text-black tw-text-base tw-mt-6"
                            >
                                Size Range (SQM)
                            </h3>

                            <div>
                                <label
                                    v-for="sizeRange in sizeRangesOffices"
                                    class="quick-search__checkbox"
                                >
                                    <input
                                        :value="sizeRange.value"
                                        type="checkbox"
                                        v-model="checkedSizeRanges"
                                        @change="getQuickSearchResults"
                                    />
                                    <div>{{ sizeRange.name }}</div>
                                </label>
                            </div>
                        </template> -->

                        <template v-if="checkedPropertyType != 'Offices'">
                            <h3
                                class="tw-font-medium tw-text-black tw-text-base tw-mt-6"
                            >
                                Bedroom Range
                            </h3>

                            <div>
                                <label
                                    v-for="bedroomRange in bedroomRanges"
                                    class="quick-search__checkbox"
                                >
                                    <input
                                        :value="bedroomRange.value"
                                        type="checkbox"
                                        v-model="checkedBedroomRanges"
                                        @change="getQuickSearchResults"
                                    />
                                    <div>{{ bedroomRange.name }}</div>
                                </label>
                            </div>
                        </template>
                        
                        <h3
                            class="tw-font-medium tw-text-black tw-text-base tw-mt-6"
                        >
                            Status
                        </h3>

                        <div class="tw-flex tw-flex-col">
                            <!-- <div>
                                <label class="quick-search__checkbox">
                                    <input
                                        @change="getQuickSearchResults"
                                        type="checkbox"
                                        value="Planned"
                                        v-model="checkedStatuses"
                                    />
                                    <div>
                                        <span
                                            class="quick-search__planning-icon"
                                        ></span>
                                        Planning
                                        <i class="fa-solid fa-check"></i>
                                    </div>
                                </label>
                            </div>

                            <div>
                                <label class="quick-search__checkbox">
                                    <input
                                        @change="getQuickSearchResults"
                                        type="checkbox"
                                        value="Under Construction"
                                        v-model="checkedStatuses"
                                    />
                                    <div>
                                        <span
                                            class="quick-search__under-construction-icon"
                                        ></span>
                                        Under Construction
                                        <i class="fa-solid fa-check"></i>
                                    </div>
                                </label>
                            </div> -->

                            <div>
                                <label class="quick-search__checkbox">
                                    <input
                                        @change="getQuickSearchResults"
                                        type="checkbox"
                                        value="Pre-selling"
                                        v-model="checkedStatuses"
                                    />
                                    <div>
                                        <span
                                            class="quick-search__planning-icon"
                                        ></span>
                                        Pre-selling
                                        <i class="fa-solid fa-check"></i>
                                    </div>
                                </label>
                            </div>

                            <div>
                                <label class="quick-search__checkbox">
                                    <input
                                        @change="getQuickSearchResults"
                                        type="checkbox"
                                        value="Ready for Occupancy"
                                        v-model="checkedStatuses"
                                    />
                                    <div>
                                        <span
                                            class="quick-search__ready-for-occupancy-icon"
                                        ></span>
                                        Ready for occupancy
                                        <i class="fa-solid fa-check"></i>
                                    </div>
                                </label>
                            </div>
                        </div>

                        <div class="tw-text-center lg:tw-text-left">
                            <button
                                @click="showInitialScreen"
                                class="bg-none tw-border-0 tw-mt-6"
                            >
                                <i
                                    class="fa fa-close tw-pr-2 tw-text-[12px]"
                                ></i>
                                <span
                                    class="tw-underline tw-semibold tw-text-[12px]"
                                    >CLEAR ALL SELECTIONS</span
                                >
                            </button>
                        </div>

                        <button
                            class="lg:tw-hidden tw-border tw-bg-darkGreen tw-text-white tw-py-2 tw-mt-4 tw-w-full"
                            @click="hideFilters"
                        >
                            CLOSE AND SEE RESULTS
                        </button>
                    </div>
                </section>
                <section
                    id="quickSearchDemo"
                    class="tw-relative tw-flex tw-items-center tw-content-center tw-justify-center tw-flex-col"
                >
                    <button
                        @click="closeFullMenu"
                        class="bg-none tw-border-0 tw-absolute tw-right-2.5 tw-top-2.5 tw-text-2xl tw-hidden lg:tw-block"
                    >
                        <i class="fa fa-close" aria-hidden="true"></i>
                    </button>

                    <img
                        :src="`${assetURL}/images/icons/quicksearch-large-icon.svg`"
                        alt="Search icon"
                        width=""
                        height=""
                        class="tw-max-w-full"
                    />
                    <p
                        class="tw-text-black tw-text-[24px] tw-font-regular tw-text-center"
                    >
                        Welcome to
                        <span class="tw-font-bold">Quick Search!</span> <br />
                        Start by selecting filters on the left sidebar.
                    </p>
                </section>
                <section
                    id="quickSearchResults"
                    class="tw-relative tw-my-8 lg:tw-my-0 tw-px-[20px] lg:tw-px-10 tw-overflow-y-auto tw-bg-white tw-hidden"
                >
                    <button
                        @click="closeFullMenu"
                        class="bg-none tw-border-0 tw-absolute tw-right-2.5 tw-top-2.5 tw-text-2xl tw-hidden lg:tw-block"
                    >
                        <i class="fa fa-close" aria-hidden="true"></i>
                    </button>

                    <QuickSearchLoading v-if="isLoading" />

                    <section v-if="!isLoading">
                        <h2
                            v-if="matchingProperties.length > 0"
                            class="tw-text-darkGreen tw-text-[24px] lg:tw-text-[40px] tw-font-light lg:tw-mt-14 tw-mb-0 lg:tw-mb-4 tw-py-8 lg:tw-py-0"
                        >
                            Matching Properties
                        </h2>

                        <h2
                            v-if="matchingProperties.length === 0"
                            class="tw-text-darkGreen tw-text-[24px] lg:tw-text-[40px] tw-font-light lg:tw-mt-14 tw-mb-0 lg:tw-mb-4 tw-py-8 lg:tw-py-0"
                        >
                            Sorry, we couldn't find exact matches for your
                            filters.
                        </h2>

                        <QuickSearchProperty
                            v-for="property in matchingProperties"
                            :property="property"
                            :propertyType="checkedPropertyType"
                        />

                        <h2
                            class="tw-text-darkGreen tw-text-[24px] tw-font-light lg:tw-mt-14 tw-mb-0 lg:tw-mb-4 tw-pb-8 lg:tw-pb-0"
                        >
                            These properties may interest you
                        </h2>

                        <QuickSearchProperty
                            v-for="property in otherProperties"
                            :property="property"
                        />
                    </section>
                </section>
            </div>
        </Transition>
    </section>
</template>
