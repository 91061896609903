<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Grid, Navigation, Virtual, FreeMode } from 'swiper';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import { sanitizeUrl } from '@braintree/sanitize-url';
import 'photoswipe/style.css';
import { assetURL } from '../utils/url';

export default {
    props: {
        galleryID: String,
        images: Array,
        items: Array,
        containerOffset: String,
        slug: Array,
        imagesStatic: Array,
    },
    components: {
        Swiper,
        SwiperSlide,
    },
    setup(props) {
        let swiperRef = null;
        const setSwiperRef = (swiper) => {
            swiperRef = swiper;
        };
        const slideTo = (index) => {
            swiperRef.slideTo(index - 1, 0);
        };

        return {
            imagesData: props.imagesStatic,
            swiperRef: null,
            setSwiperRef,
            slideTo,
            modules: [Grid, Navigation, Virtual, FreeMode],
            modulesDesktop: [Navigation, Virtual],
            containerOffset: props.containerOffset,
        };
    },
    data() {
        return {
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            width: document.documentElement.clientWidth,
            height: document.documentElement.clientHeight,
            staticURL: `${assetURL}images/properties/gallery/1.jpg`,
            propSLUG: this.slug,
        };
    },
    mounted() {
        // for swiper
        window.addEventListener('resize', this.getDimensions);

        // for lightbox images
        if (!this.lightbox) {
            this.lightbox = new PhotoSwipeLightbox({
                gallery: `#${this.$props.galleryID}`,
                children: 'a',

                showHideAnimationType: 'none',

                pswpModule: () => import('photoswipe'),
            });

            this.lightbox.init();
        }
    },
    unmounted() {
        // for swiper
        window.removeEventListener('resize', this.getDimensions);

        // for lightbox images
        if (this.lightbox) {
            this.lightbox.destroy();
            this.lightbox = null;
        }
    },
    methods: {
        sanitize(url) {
            return sanitizeUrl(url);
        },
        getDimensions() {
            this.width = document.documentElement.clientWidth;
            this.height = document.documentElement.clientHeight;
        },
        getImageSize() {
            const img = new Image();
            img.src = this.$refs.image.src;
            img.onload = () => {
                this.width = img.width;
                this.height = img.height;
            };
        },
    },
};
</script>

<template>
    <div class="container">
        <div class="row mb-30px">
            <div class="col-12" :class="containerOffset">
                <div class="gallery-filter__wrapper">
                    <p class="fs-48px fw-extrabold tw-text-darkGreen lh-1">Gallery</p>
                </div>
                <!-- <div
                    class="gallery-filter__wrapper"
                    v-for="(item, key) in items"
                >
                    <button
                        v-if="item.exterior_images.length"
                        @click="slideTo(0)"
                        class="mb-10px mr-10px mb-0px-xl button button-rounded button-outline text-decoration-none text-center py-10px px-15px"
                    >
                        Outdoor
                    </button>
                    <button
                        v-if="item.interior_images.length"
                        @click="slideTo(5)"
                        class="mb-10px mr-10px mb-0px-xl button button-rounded button-outline text-decoration-none text-center py-10px px-15px"
                    >
                        Indoor
                    </button>
                    <button
                        v-if="item.amenities_images.length"
                        @click="slideTo(16)"
                        class="mb-10px mr-10px mb-0px-xl button button-rounded button-outline text-decoration-none text-center py-10px px-15px"
                    >
                        Amenities
                    </button>
                </div> -->
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 p-0px">
                <div
                    class="tw-relative tw-w-[100vw] swiper-mobile"
                    v-if="width <= 1199"
                    :id="galleryID"
                >
                    <swiper
                        v-for="(item, key) in items"
                        :slidesPerView="2"
                        :grid="{
                            rows: 2,
                        }"
                        :spaceBetween="0"
                        :navigation="true"
                        :modules="modules"
                        @swiper="setSwiperRef"
                    >
                        <swiper-slide
                            v-if="item.exterior_images !== null"
                            v-for="ext in item.exterior_images"
                            virtualIndex="0"
                        >
                            <div class="swiper-content">
                                <a
                                    :key="key"
                                    :href="this.sanitize(ext)"
                                    :data-pswp-srcset="ext"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img :src="ext" />
                                </a>
                            </div>
                        </swiper-slide>
                        <swiper-slide
                            v-if="item.interior_images !== null"
                            v-for="ind in item.interior_images"
                            virtualIndex="5"
                        >
                            <div class="swiper-content">
                                <a
                                    :key="key"
                                    :href="this.sanitize(ind)"
                                    :data-pswp-srcset="ind"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img :src="ind" />
                                </a>
                            </div>
                        </swiper-slide>
                        <swiper-slide
                            v-if="item.amenities_images !== null"
                            v-for="amt in item.amenities_images"
                            virtualIndex="16"
                        >
                            <div class="swiper-content">
                                <a
                                    :key="key"
                                    :href="this.sanitize(amt)"
                                    :data-pswp-srcset="amt"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img :src="amt" />
                                </a>
                            </div>
                        </swiper-slide>
                    </swiper>
                </div>
                <div
                    class="image-gallery__wrapper"
                    v-if="width >= 1200"
                    :id="galleryID"
                >
                    <swiper
                        v-for="(item, key) in items"
                        :modules="modulesDesktop"
                        :centeredSlides="false"
                        :navigation="true"
                        :virtual="true"
                        slidesPerView="3.50"
                        class="image-gallery__swiper"
                        @swiper="setSwiperRef"
                    >
                        <swiper-slide
                            v-if="item.exterior_images !== null"
                            v-for="(ext, index) in item.exterior_images"
                            virtualIndex="0"
                        >
                            <div class="swiper-content">
                                <a
                                    :key="index"
                                    :href="this.sanitize(ext)"
                                    :data-pswp-srcset="ext"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img :src="ext" />
                                </a>
                            </div>
                        </swiper-slide>
                        <swiper-slide
                            v-if="item.interior_images !== null"
                            v-for="ind in item.interior_images"
                            virtualIndex="5"
                        >
                            <div class="swiper-content">
                                <a
                                    :key="key"
                                    :href="this.sanitize(ind)"
                                    :data-pswp-srcset="ind"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img :src="ind" />
                                </a>
                            </div>
                        </swiper-slide>
                        <swiper-slide
                            v-if="item.amenities_images !== null"
                            v-for="amt in item.amenities_images"
                            virtualIndex="16"
                        >
                            <div class="swiper-content">
                                <a
                                    :key="key"
                                    :href="this.sanitize(amt)"
                                    :data-pswp-srcset="amt"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img :src="amt" />
                                </a>
                            </div>
                        </swiper-slide>
                    </swiper>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.pswp {
    img {
        object-fit: contain;
        padding: 50px;
    }
}
.swiper-mobile {
    .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;
        height: calc((100% - 10px) / 2) !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .swiper-slide {
        a {
            position: relative;
            display: block;
            width: 100%;
            height: 100%;
            img {
                width: 100%;
                height: 100%;
                max-width: 100%;
                object-fit: cover;
            }
        }
    }
    .swiper-content {
        height: 100%;
        width: 100%;
    }
}
.swiper-grid-column > .swiper-wrapper {
    height: 70vw;
}
.image-gallery__wrapper {
    .swiper-wrapper {
        height: 120vw;
        max-height: 600px;
        flex-direction: column;
        align-items: center;
        align-content: stretch;
        flex-wrap: wrap;
        justify-content: center;
    }
    .swiper-slide {
        cursor: grab;
        text-align: center;
        width: calc(25% - 45px);
        height: calc((100% - 30px) / 2);
    }
    .swiper-content {
        height: auto;
    }
    img {
        width: 100%;
        height: 140px;
        object-fit: cover;
        max-width: 100%;
    }
}

.image-gallery__swiper,
.swiper-mobile {
    .swiper-button-next,
    .swiper-button-prev {
        height: calc(100% - 10px);
        margin: 0;
        width: 32px;
        background-color: #4a584682;
        color: #ffffff;
        &::after {
            width: 9px;
            height: 35px;
            font-size: 20px;
            display: flex;
            align-items: center;
            flex-direction: row;
        }
    }
    .swiper-button-next {
        right: 0;
    }
    .swiper-button-prev {
        left: 0;
    }
}
.image-gallery__swiper {
    .swiper-button-next,
    .swiper-button-prev {
        top: 15px;
    }
}
.swiper-mobile {
    .swiper-button-next,
    .swiper-button-prev {
        top: 0px;
    }
}

@media (min-width: 1200px) {
    .image-gallery__wrapper {
        .swiper {
            width: 100%;
            max-width: 1920px;
        }
        .swiper-wrapper,
        .swiper-slide {
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            -webkit-align-items: center;
            align-items: center;
            height: 240px;
        }
        .swiper-content {
            width: 100%;
            height: 100%;
        }
    }

    .image-gallery__swiper {
        img {
            width: 100%;
            height: 100%;
            -o-object-fit: cover;
            object-fit: cover;
            max-width: 100%;
        }
        .swiper-button-next,
        .swiper-button-prev {
            height: 100%;
            top: 0;
            background-color: #4a5846;
        }
        .swiper-button-prev {
            left: 0px;
        }
    }
}

@media (min-width: 1800px) {
    .image-gallery__wrapper {
        .swiper-wrapper,
        .swiper-slide {
            height: 350px;
        }
    }
}
</style>
