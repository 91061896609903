<script setup>
import { ref } from 'vue';
import StepProgress from './StepProgress.vue';

const stepProgress = ref();
const data = {
    steps: ['1', '2', '3'],
    currentStep: 1,
    activeColor: '#7C826B',
    passiveColor: '#CFD0D0',
};
</script>

<template>
    <StepProgress :data="data" ref="stepProgress"></StepProgress>
    <div class="container">
        <div class="row">
            <div class="col-12 col-lg-3 order-lg-2 offset-lg-6 buttons-wrapper">
                <div class="prev-button" @click="stepProgress.prevStep">
                    <i
                        aria-hidden="true"
                        class="fa fa-chevron-left fw-bold"
                    ></i>
                </div>
                <div class="next-button" @click="stepProgress.nextStep">
                    Next
                    <i
                        aria-hidden="true"
                        class="fa fa-chevron-right fw-bold"
                    ></i>
                </div>
            </div>
            <div
                class="col-12 col-lg-3 order-lg-1 back-button-wrapper mt-40px mt-0px-lg"
            >
                <a
                    href="/contact-us"
                    class="fs-14px fw-semibold tw-text-accentGreen hover:tw-text-accentGreen"
                    >BACK TO START</a
                >
            </div>
        </div>
    </div>

    <div class="py-50px"></div>
</template>
