const cookiePolicy = document.getElementById('cookie-policy');
const quickSearchDemoBackground = document.getElementById(
    'quickSearchDemoBackground'
);
const cookiePolicyAccepted = localStorage.getItem('cookiePolicyAccepted');

if (cookiePolicyAccepted) {
    cookiePolicy.remove();
    quickSearchDemoBackground?.remove();
}

if (cookiePolicy && !cookiePolicyAccepted) {
    cookiePolicy.classList.remove('tw-hidden');

    document
        .getElementById('accept-cookie-policy')
        .addEventListener('click', () => {
            cookiePolicy.classList.add('tw-opacity-0');
            localStorage.setItem('cookiePolicyAccepted', 'true');
            setTimeout(() => {
                cookiePolicy.remove();
            }, 220);
        });
}
