import { createApp } from 'vue';
import QuickSearchButton from '../components/QuickSearchButton.vue';
import QuickSearchMenu from '../components/QuickSearchMenu.vue';

const app = createApp(QuickSearchButton, {
    // add props here
});
app.mount('#quickSearchButton');

const quickSearchMenu = createApp(QuickSearchMenu, {
    // add props here
    initial: 1,
    // locations: quickSearchLocations,
    // priceRanges: quickSearchPriceRanges,
    // sizeRanges: quickSearchSizeRanges,
    // sizeRangesOffices: quickSearchOfficeSizeRanges,
    // bedroomRanges: quickSearchBedroomRanges,
});
quickSearchMenu.mount('#quickSearchMenu');
