const property_type_filter = document.querySelectorAll('.search-filter__radio');

const onClick = () => {
    console.log('clicked');
    document.getElementById('searchForm').submit();
};

property_type_filter.forEach((item) => {
    item.addEventListener('click', onClick);
});
