<template>
    <section>
        <h2
            class="tw-text-darkGreen tw-text-[24px] lg:tw-text-[40px] tw-font-light lg:tw-mt-14 tw-mb-0 lg:tw-mb-4 tw-py-8 lg:tw-py-0"
        >
            Please wait a moment as we load your results.
        </h2>

        <div
            v-for="index in 2"
            class="shadow tw-p-6 tw-bg-white tw-relative tw-grid tw-grid-cols-1 lg:tw-grid-cols-30pct-1fr tw-gap-6 tw-mb-8"
        >
            <div class="tw-relative tw-bg-grey1">
                <picture>
                    <source type="image/webp" srcset="" />
                    <img
                        src=""
                        alt=""
                        width=""
                        height=""
                        class="tw-max-w-full tw-w-full"
                    />
                </picture>
            </div>

            <div>
                <h3
                    class="tw-text-darkGreen tw-text-[24px] tw-font-bold lg:tw-max-w-50pct tw-bg-grey1 tw-h-[40px]"
                ></h3>
                <p
                    class="tw-uppercase tw-text-darkTeal tw-bg-grey1 tw-inline-block tw-py-2 tw-px-4 tw-mb-0 tw-bg-grey1 tw-w-9/12 lg:tw-w-3/12 tw-h-[40px]"
                ></p>

                <div
                    class="tw-grid lg:tw-grid-cols-1fr-min-content tw-mt-6 tw-mb-2"
                >
                    <div class="tw-bg-grey1">
                        <div
                            class="tw-flex tw-items-center tw-content-center tw-gap-2 tw-mb-6"
                        >
                            <p class="tw-mb-0 tw-text-darkTeal"></p>
                        </div>
                        <div class="tw-grid lg:tw-grid-cols-2 tw-gap-2.5">
                            <p>
                                <span
                                    class="tw-font-bold tw-text-[20px] tw-text-darkGreen"
                                ></span
                                ><br />
                                <span class="tw-text-darkTeal"></span>
                            </p>
                            <p>
                                <span
                                    class="tw-font-bold tw-text-[20px] tw-text-darkGreen"
                                ></span
                                ><br />
                                <span class="tw-text-darkTeal"></span>
                            </p>
                        </div>
                    </div>

                    <div class="tw-grid tw-gap-3"></div>
                </div>
            </div>
        </div>
    </section>
</template>
