<script setup>
import { ref } from 'vue';
import { assetURL, baseURL } from '../utils/url';
import { useMainMenuStore } from '../store/MainMenuStore';

const store = useMainMenuStore();
const { showQuickSearch } = store;

function openFullMenu() {
    showQuickSearch();
}
</script>

<template>
    <button
        @click="openFullMenu"
        class="bg-none tw-border-0 nav__mobile-button"
        title="Open Quick Search"
    >
        <picture>
            <source
                type="image/webp"
                :srcset="`${assetURL}images/icons/search.webp`"
            />
            <img
                :src="`${assetURL}images/icons/search.png`"
                alt=""
                width=""
                height=""
                class="tw-max-w-full nav__mobile-icon"
            />
        </picture>
        <p
            class="tw-text-[8px] tw-text-center tw-mb-0 tw-hidden lg:tw-block tw-leading-tight tw-text-black tw-mt-[8px]"
        >
            <b>QUICK<br />SEARCH</b>
        </p>
    </button>
</template>
