<script setup>
import { ref, onMounted} from 'vue';
import { storeToRefs } from 'pinia';
import { sanitizeUrl } from '@braintree/sanitize-url';
import AOS from 'aos';
import { assetURL, baseURL } from '../utils/url';
import { useMainMenuStore } from '../store/MainMenuStore';

import LocationsMegaMenu from './LocationsMegaMenu.vue';
import EstatesMegaMenu from './EstatesMegaMenu.vue';
import PropertiesMegaMenu from './PropertiesMegaMenu.vue';
import axios from 'axios';

// const props = defineProps(['locations', 'estates', 'properties']);

const store = useMainMenuStore();
const {
    hideMegaMenu,
    showQuickSearch,
    setLocations,
    setEstates,
    setProperties,
} = store;
const { isShowingMegaMenu, isShowingLocations } = storeToRefs(store);

// const isShowingLocations = ref(true);
const isShowingEstates = ref(false);
const isShowingProperties = ref(false);
const isShowingMainMenu = ref(true);

function getLocation() {

    axios.get('/mega-menu/locations').then(({ data }) => {
        setLocations(data);
    });
}

function getEstate() {
    axios.get('/mega-menu/estates').then(({ data }) => {
        setEstates(data);
    });
}

function getProperties() {
    axios.get('/mega-menu/properties').then(({ data }) => {
        setProperties(data);
    });
}

function closeFullMenu() {
    hideMegaMenu();
    isShowingMainMenu.value = true;
    isShowingLocations.value = false;
    isShowingEstates.value = false;
    isShowingProperties.value = false;
}

function openFullMenu() {
    isShowingMegaMenu.value = true;
    if (window.innerWidth > 991) {
        showLocations();
    }
}

function showLocations() {
    isShowingMainMenu.value = window.innerWidth > 991;
    isShowingLocations.value = true;
    isShowingEstates.value = false;
    isShowingProperties.value = false;
}

function showEstates() {
    isShowingMainMenu.value = window.innerWidth > 991;
    isShowingEstates.value = true;
    isShowingLocations.value = false;
    isShowingProperties.value = false;
}

function showProperties() {
    isShowingMainMenu.value = window.innerWidth > 991;
    isShowingProperties.value = true;
    isShowingLocations.value = false;
    isShowingEstates.value = false;
}

function backToMainMenu() {
    isShowingMainMenu.value = true;
    isShowingEstates.value = false;
    isShowingLocations.value = false;
    isShowingProperties.value = false;
}
onMounted(() => {
      AOS.init();
      getLocation();
      getEstate();
      getProperties();
    });
</script>

<template>
    <section>
        <Transition name="mega-menu">
            <div
                v-if="isShowingMegaMenu"
                class="tw-fixed tw-top-0 tw-left-0 tw-bg-grey1 tw-w-full tw-h-full tw-grid lg:tw-grid-cols-megamenu-desktop tw-z-[5]"
            >
                <nav
                    v-if="isShowingMainMenu"
                    class="tw-grid tw-grid-cols-1 tw-gap-y-2.5 tw-auto-rows-min tw-my-8 tw-px-[20px] lg:tw-px-8 tw-border-r tw-border-l-0 tw-border-y-0 tw-border-solid tw-border-grey2"
                >
                    <section
                        class="lg:tw-mr-0 tw-grid tw-grid-cols-navbar-grid lg:tw-grid-cols-1 tw-content-center tw-justify-center tw-gap-x-2.5"
                    >
                        <a :href="sanitizeUrl(baseURL)" class="tw-mr-[30px]">
                            <picture>
                                <source
                                    type="image/webp"
                                    :srcset="`${assetURL}/images/ortigas-land-mobile-logo.webp`"
                                />
                                <img
                                    :src="`${assetURL}/images/ortigas-land-mobile-logo.png`"
                                    alt="Ortigas Land Logo"
                                    width=""
                                    height=""
                                    class="tw-max-w-full"
                                />
                            </picture>
                        </a>
                        <div class="lg:tw-self-center lg:tw-hidden"></div>
                        <div class="tw-text-right lg:tw-hidden">
                            <button
                                @click="closeFullMenu"
                                title="Close mega menu"
                                class="bg-none tw-border-0 tw-text-darkGreen tw-text-2xl"
                            >
                                <i class="fa fa-close" aria-hidden="true"></i>
                            </button>
                        </div>
                    </section>

                    <ul
                        class="tw-pl-0 tw-list-none tw-grid tw-grid-cols-1 tw-gap-y-4 tw-mt-8 tw-mb-0"
                    >
                        <li>
                            <a
                                class="tw-text-grey3 hover:tw-text-black tw-grid tw-grid-cols-1fr-max-content tw-no-underline"
                                :href="sanitizeUrl(`${baseURL}`)"
                                >Home</a
                            >
                        </li>
                        <li>
                            <button
                                @click="showLocations"
                                :class="[
                                    isShowingLocations
                                        ? 'tw.text-black'
                                        : 'tw-text-grey3',
                                    'hover:tw-text-black',
                                    'tw-grid',
                                    'tw-grid-cols-1fr-max-content',
                                    'tw-no-underline',
                                    'bg-none',
                                    'tw-border-0',
                                    'tw-p-0',
                                    'tw-w-full',
                                    'tw-text-left',
                                ]"
                            >
                                Locations
                                <i
                                    class="fa fa-solid fa-chevron-right"
                                    aria-hidden="true"
                                ></i>
                            </button>
                        </li>
                        <li>
                            <button
                                @click="showEstates"
                                :class="[
                                    isShowingEstates
                                        ? 'tw.text-black'
                                        : 'tw-text-grey3',
                                    'hover:tw-text-black',
                                    'tw-grid',
                                    'tw-grid-cols-1fr-max-content',
                                    'tw-no-underline',
                                    'bg-none',
                                    'tw-border-0',
                                    'tw-p-0',
                                    'tw-w-full',
                                    'tw-text-left',
                                ]"
                            >
                                Estates
                                <i
                                    class="fa fa-solid fa-chevron-right"
                                    aria-hidden="true"
                                ></i>
                            </button>
                        </li>
                        <li>
                            <button
                                @click="showProperties"
                                :class="[
                                    isShowingProperties
                                        ? 'tw.text-black'
                                        : 'tw-text-grey3',
                                    'hover:tw-text-black',
                                    'tw-grid',
                                    'tw-grid-cols-1fr-max-content',
                                    'tw-no-underline',
                                    'bg-none',
                                    'tw-border-0',
                                    'tw-p-0',
                                    'tw-w-full',
                                    'tw-text-left',
                                ]"
                            >
                                Properties
                                <i
                                    class="fa fa-solid fa-chevron-right"
                                    aria-hidden="true"
                                ></i>
                            </button>
                        </li>
                        <li>
                            <a
                                class="tw-text-grey3 hover:tw-text-black tw-grid tw-grid-cols-1fr-max-content tw-no-underline"
                                :href="sanitizeUrl(`${baseURL}/about-us`)"
                                >About Us</a
                            >
                        </li>
                        <li>
                            <a
                                class="tw-text-grey3 hover:tw-text-black tw-grid tw-grid-cols-1fr-max-content tw-no-underline"
                                :href="sanitizeUrl(`${baseURL}/contact-us`)"
                                >Contact Us</a
                            >
                        </li>
                    </ul>

                    <form
                        :action="`${baseURL}/site-search`"
                        class="tw-relative tw-my-8"
                    >
                        <input
                            class="menu__search-input tw-pl-10 tw-pr-2 tw-w-full tw-py-2 tw-border tw-border-solid tw-border-grey2"
                            type="search"
                            name="keyword"
                            placeholder="Search this website"
                            required
                        />
                        <button
                            class="bg-none tw-border-0 tw-absolute tw-left-2 tw-top-2"
                            type="submit"
                        >
                            <!-- <i
                                class="fa-solid fa-magnifying-glass tw-text-xl"
                            ></i> -->
                        </button>
                    </form>
                    <div class="menu__CTA-wrapper">
                        <a
                            class="mb-8px tw-bg-darkGreen hover:tw-bg-darkGreen-950 tw-border-0 tw-text-white hover:tw-text-white tw-no-underline tw-text-base tw-transition-all tw-duration-300 tw-py-4 tw-px-6 tw-text-center tw-inline-block"
                            :href="
                                sanitizeUrl(`${baseURL}/contact-us/quotation`)
                            "
                            >INQUIRE PRICE</a
                        >

                        <a
                            class="tw-bg-transparent hover:tw-bg-darkGreen-950 tw-text-darkGreen hover:tw-text-white tw-no-underline tw-text-base tw-transition-all tw-duration-300 tw-py-4 tw-px-6 tw-text-center tw-border tw-border-solid tw-border-darkGreen tw-inline-block"
                            :href="sanitizeUrl(`${baseURL}/contact-us/visit`)"
                            >SCHEDULE A VISIT</a
                        >
                    </div>
                </nav>

                <Transition name="inner-menu">
                    <LocationsMegaMenu
                        @close-menu="closeFullMenu"
                        @back-to-main-menu="backToMainMenu"
                        v-if="isShowingLocations"
                    />
                </Transition>

                <Transition name="inner-menu">
                    <EstatesMegaMenu
                        @close-menu="closeFullMenu"
                        @back-to-main-menu="backToMainMenu"
                        v-if="isShowingEstates"
                    />
                </Transition>

                <Transition name="inner-menu">
                    <PropertiesMegaMenu
                        @close-menu="closeFullMenu"
                        @back-to-main-menu="backToMainMenu"
                        v-if="isShowingProperties"
                    />
                </Transition>
            </div>
        </Transition>
    </section>
</template>
