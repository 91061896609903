<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Controller, Navigation } from 'swiper';
import { storeToRefs } from 'pinia';
import { sanitizeUrl } from '@braintree/sanitize-url';
import { assetURL, baseURL } from '../utils/url';
import TryQuickSearch from './TryQuickSearch.vue';
import { useMainMenuStore } from '../store/MainMenuStore';

const store = useMainMenuStore();
const { showQuickSearch } = store;
const emit = defineEmits(['closeMenu', 'backToMainMenu']);
const modules = [Controller, Navigation];
const navButtons = {
    prevEl: '.prev-btn',
    nextEl: '.next-btn',
};

const {
    locations,
    locationSlideView,
    locationSlideViewDesktop,
    locationSpaceBetween,
    isDesktop,
} = storeToRefs(store);

function closeFullMenu() {
    emit('closeMenu');
}

function backToMainMenu() {
    emit('backToMainMenu');
}
function sanitize(url) {
    return sanitizeUrl(url);
}
</script>

<template>
    <section
        class="tw-fixed lg:tw-relative tw-top-0 tw-left-0 tw-py-8 lg:tw-pt-0 tw-px-[20px] full-height tw-w-full lg:tw-pl-12 lg:tw-pr-0 tw-overflow-y-auto tw-z-10 tw-bg-grey1 tw-max-h-screen"
    >
        <section
            class="lg:tw-mr-[30px] tw-mb-8 tw-grid tw-grid-cols-inner-megamenu-grid lg:tw-grid-cols-1 tw-content-end tw-items-end tw-justify-center tw-gap-x-2.5"
        >
            <button
                @click="backToMainMenu"
                class="bg-none tw-border-0 lg:tw-hidden"
            >
                <i class="fa fa-chevron-left" aria-hidden="true"></i>
            </button>
            <h2
                class="tw-text-darkGreen tw-text-[24px] lg:tw-text-[40px] tw-font-light lg:tw-mt-14 tw-mb-0 lg:tw-mb-4 lg:tw-hidden"
            >
                Featured Location
            </h2>
            <div class="lg:tw-self-center lg:tw-hidden">
                <button
                    @click="showQuickSearch"
                    class="bg-none tw-border-0 p-0px"
                    title="Open Quick Search"
                >
                    <picture>
                        <source
                            type="image/webp"
                            :srcset="`${assetURL}images/icons/search.webp`"
                        />
                        <img
                            :src="`${assetURL}images/icons/search.png`"
                            alt="Search icon"
                            width=""
                            height=""
                            class="tw-max-w-full lg:tw-px-4"
                        />
                    </picture>
                    <p
                        class="tw-text-xs tw-text-center tw-mb-0 tw-hidden lg:tw-block"
                    >
                        <b>QUICK SEARCH</b>
                    </p>
                </button>
            </div>
            <div class="tw-text-right">
                <button
                    @click="closeFullMenu"
                    title="Close mega menu"
                    class="bg-none tw-border-0 lg:tw-absolute lg:tw-right-2.5 lg:tw-top-2.5 tw-text-darkGreen tw-text-2xl tw-leading-none"
                >
                    <i class="fa fa-close" aria-hidden="true"></i>
                </button>
            </div>
        </section>
        <section
            class="tw-mt-14 tw-mb-8 tw-block lg:tw-grid tw-grid-cols-2 lg:tw-pr-12"
        >
            <h2
                class="tw-text-darkGreen tw-text-[0px] lg:tw-text-[40px] tw-font-light tw-invisible lg:tw-visible"
            >
                LOCATIONS
            </h2>
            <div class="tw-text-right">
                <a
                    class="tw-text-semibold tw-text-darkGreen hover:tw-text-darkGreen tw-no-underline hover:tw-underline tw-tracking-widest"
                    :href="sanitize(`${baseURL}/locations`)"
                    >ALL LOCATIONS
                    <i
                        class="fa fa-chevron-right tw-pl-2"
                        aria-hidden="true"
                    ></i
                ></a>
            </div>
        </section>

        <section class="tw-relative">
            <div v-if="isDesktop">
                <button
                    class="prev-btn tw-text-4xl tw-absolute tw-top-1/3 tw-z-10 -tw-left-8 md:-tw-left-12 tw-bg-white tw-border-none tw-text-accentGreen tw-w-12 tw-h-12"
                >
                    &lt;
                </button>

                <button
                    class="next-btn tw-text-4xl tw-absolute tw-top-1/3 tw-z-10 tw-right-0 tw-bg-white tw-border-none tw-text-accentGreen tw-w-12 tw-h-12"
                >
                    &gt;
                </button>
            </div>

            <swiper
                v-if="isDesktop"
                :modules="modules"
                :navigation="navButtons"
                :slidesPerView="locationSlideViewDesktop"
                :spaceBetween="locationSpaceBetween"
            >
                <swiper-slide
                    v-for="(location, index) in locations"
                    :key="location.id"
                >
                    <div
                        class="tw-grid tw-grid-cols-30pct-1fr lg:tw-grid-cols-1"
                        data-aos="fade-down"
                        :data-aos-delay="index * 300"
                        data-aos-once="true"
                        data-aos-duration="300"
                    >
                        <a :href="sanitize(location.innerPageLink)">
                            <figure
                                class="tw-h-full lg:tw-aspect-9/11 tw-mb-0 tw-bg-white"
                            >
                                <picture>
                                    <source
                                        type="image/webp"
                                        :srcset="location.webpImage"
                                    />
                                    <img
                                        :src="location.webpImage"
                                        :alt="`${location.name} thumbnail`"
                                        width=""
                                        height=""
                                        class="tw-w-full tw-h-full"
                                    />
                                </picture>
                            </figure>
                        </a>
                        <div class="tw-p-8 tw-bg-white">
                            <h3
                                class="tw-text-2xl tw-font-bold tw-text-black tw-mb-4 menu__location-name"
                            >
                                {{ location.name }}
                            </h3>
                            <div class="menu__location-tags">
                                <a
                                    class="tw-bg-transparent tw-text-[10px] hover:tw-bg-darkGreen-950 tw-text-darkGreen hover:tw-text-white tw-no-underline tw-transition-all tw-duration-300 tw-py-2 tw-px-4 tw-text-center tw-border tw-border-solid tw-border-darkGreen tw-font-semibold tw-mb-4 lg:tw-mb-0 tw-mr-4 tw-inline-block"
                                    :href="sanitize(location.estatesScrollLink)"
                                    >ESTATES</a
                                >
                                <a
                                    class="tw-bg-transparent tw-text-[10px] hover:tw-bg-darkGreen-950 tw-text-darkGreen hover:tw-text-white tw-no-underline tw-transition-all tw-duration-300 tw-py-2 tw-px-4 tw-text-center tw-border tw-border-solid tw-border-darkGreen tw-font-semibold tw-inline-block"
                                    :href="
                                        sanitize(location.propertiesScrollLink)
                                    "
                                    >PROPERTIES</a
                                >
                            </div>
                        </div>
                    </div>
                </swiper-slide>
            </swiper>

            <section
                class="tw-grid lg:tw-grid-cols-3 tw-gap-4 lg:tw-gap-10"
                v-if="!isDesktop"
            >
                <div
                    v-for="(location, index) in locations"
                    :key="location.id"
                    class="tw-grid lg:tw-grid-cols-1 menu-location__grid"
                    data-aos="fade-left"
                    :data-aos-delay="index * 300"
                    data-aos-once="true"
                    data-aos-duration="300"
                >
                    <a :href="sanitize(location.innerPageLink)">
                        <figure
                            class="tw-h-full lg:tw-aspect-9/11 tw-mb-0 tw-bg-white menu-location__figure"
                        >
                            <picture>
                                <source
                                    type="image/webp"
                                    :srcset="location.webpImage"
                                />
                                <img
                                    :src="location.webpImage"
                                    :alt="`${location.name} thumbnail`"
                                    width=""
                                    height=""
                                    class="ratio ratio-1x1 tw-w-full tw-h-full menu-location__thumbnail"
                                />
                            </picture>
                        </figure>
                    </a>
                    <div class="tw-p-4 sm:tw-p-8 tw-bg-white tw-w-auto">
                        <h3
                            class="tw-text-2xl tw-font-bold tw-text-black tw-mb-4"
                        >
                            {{ location.name }}
                        </h3>
                        <div
                            class="tw-grid tw-grid-cols-2 tw-gap-2 menu__location-tags"
                        >
                            <a
                                class="tw-bg-transparent tw-text-[10px] hover:tw-bg-darkGreen-950 tw-text-darkGreen hover:tw-text-white tw-no-underline tw-transition-all tw-duration-300 tw-py-2 tw-text-center tw-border tw-border-solid tw-border-darkGreen tw-font-semibold tw-inline-block"
                                :href="sanitize(location.estatesScrollLink)"
                                >ESTATES</a
                            >
                            <a
                                class="tw-bg-transparent tw-text-[10px] hover:tw-bg-darkGreen-950 tw-text-darkGreen hover:tw-text-white tw-no-underline tw-transition-all tw-duration-300 tw-py-2 tw-text-center tw-border tw-border-solid tw-border-darkGreen tw-font-semibold tw-inline-block"
                                :href="sanitize(location.propertiesScrollLink)"
                                >PROPERTIES</a
                            >
                        </div>
                    </div>
                </div>
            </section>
        </section>

        <TryQuickSearch />
    </section>
</template>

<style lang="scss">
.menu-location__grid {
    grid-template-columns: 40vw 1fr;
}
.menu__location-tags {
    max-width: calc(160px * 2);
    a {
        max-width: 160px;
    }
}
.menu-location__figure {
    height: 30vw;
}
@media only screen and (min-width: 992px) {
    .menu__location-name {
        line-height: 1.2;
        min-height: 50px;
        height: auto;
    }
    .menu__location-tags {
        max-width: fit-content;
        a {
            width: 95px;
            margin: 0 0 5px 0;
        }
    }
    .menu-location__thumbnail {
        object-fit: cover;
        object-position: center;
    }
}
@media only screen and (min-width: 1200px) {
    .menu__location-name {
        min-height: 29px;
    }
    .menu__location-tags {
        display: flex;
        justify-content: space-between;
        a {
            margin: 0 5px 0 0;
        }
    }
}
</style>
