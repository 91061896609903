<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Controller, Navigation } from 'swiper';

import { storeToRefs } from 'pinia';
import { sanitizeUrl } from '@braintree/sanitize-url';
import { assetURL, baseURL } from '../utils/url';
import TryQuickSearch from './TryQuickSearch.vue';
import { useMainMenuStore } from '../store/MainMenuStore';

const store = useMainMenuStore();
const { showQuickSearch } = store;
const emit = defineEmits(['closeMenu', 'backToMainMenu']);
const modules = [Controller, Navigation];
const navButtons = {
    prevEl: '.prev-btn',
    nextEl: '.next-btn',
};

const { estates, estateSlideView, estateSpaceBetween, isDesktop } =
    storeToRefs(store);

function closeFullMenu() {
    emit('closeMenu');
}

function backToMainMenu() {
    emit('backToMainMenu');
}
function sanitize(url) {
    return sanitizeUrl(url);
}
</script>

<template>
    <section
        class="tw-fixed lg:tw-relative tw-top-0 tw-left-0 tw-py-8 lg:tw-pt-0 tw-px-[20px] full-height tw-w-full lg:tw-pl-12 lg:tw-pr-0 tw-overflow-y-auto tw-z-10 tw-bg-grey1 tw-max-h-screen"
    >
        <section
            class="lg:tw-mr-[30px] tw-mb-8 tw-grid tw-grid-cols-inner-megamenu-grid lg:tw-grid-cols-1 tw-content-end tw-items-end tw-justify-center tw-gap-x-2.5"
        >
            <button
                @click="backToMainMenu"
                class="bg-none tw-border-0 lg:tw-hidden"
            >
                <i class="fa fa-chevron-left" aria-hidden="true"></i>
            </button>
            <h2
                class="tw-text-darkGreen tw-text-[24px] lg:tw-text-[40px] tw-font-light lg:tw-mt-14 tw-mb-0 lg:tw-mb-4 lg:tw-hidden"
            >
                Featured Estates
            </h2>
            <div class="lg:tw-self-center lg:tw-hidden">
                <button
                    @click="showQuickSearch"
                    class="bg-none tw-border-0 p-0px"
                    title="Open Quick Search"
                >
                    <picture>
                        <source
                            type="image/webp"
                            :srcset="`${assetURL}images/icons/search.webp`"
                        />
                        <img
                            :src="`${assetURL}images/icons/search.png`"
                            alt="Search icon"
                            width=""
                            height=""
                            class="tw-max-w-full lg:tw-px-4"
                        />
                    </picture>
                    <p
                        class="tw-text-xs tw-text-center tw-mb-0 tw-hidden lg:tw-block"
                    >
                        <b>QUICK SEARCH</b>
                    </p>
                </button>
            </div>
            <div class="tw-text-right">
                <button
                    @click="closeFullMenu"
                    title="Close mega menu"
                    class="bg-none tw-border-0 lg:tw-absolute lg:tw-right-2.5 lg:tw-top-2.5 tw-text-darkGreen tw-text-2xl tw-leading-none"
                >
                    <i class="fa fa-close" aria-hidden="true"></i>
                </button>
            </div>
        </section>
        <section
            class="tw-mt-14 tw-mb-8 tw-block lg:tw-grid tw-grid-cols-2 lg:tw-pr-12"
        >
            <h2
                class="tw-text-darkGreen tw-text-[0px] lg:tw-text-[40px] tw-font-light tw-invisible lg:tw-visible"
            >
                Estates
            </h2>
            <div class="tw-text-right">
                <a
                    class="tw-text-semibold tw-text-darkGreen hover:tw-text-darkGreen tw-no-underline hover:tw-underline tw-tracking-widest"
                    :href="sanitize(`${baseURL}/estates`)"
                    >ALL ESTATES
                    <i
                        class="fa fa-chevron-right tw-pl-2"
                        aria-hidden="true"
                    ></i
                ></a>
            </div>
        </section>

        <section class="tw-relative">
            <div v-if="isDesktop">
                <button
                    class="prev-btn tw-text-4xl tw-absolute tw-top-1/3 tw-z-10 -tw-left-8 md:-tw-left-12 tw-bg-white tw-border-none tw-text-accentGreen tw-w-12 tw-h-12"
                >
                    &lt;
                </button>

                <button
                    class="next-btn tw-text-4xl tw-absolute tw-top-1/3 tw-z-10 tw-right-0 tw-bg-white tw-border-none tw-text-accentGreen tw-w-12 tw-h-12"
                >
                    &gt;
                </button>
            </div>
            <swiper
                class="estates-slider tw-mb-16"
                v-if="isDesktop"
                :modules="modules"
                :navigation="navButtons"
                :slidesPerView="estateSlideView"
                :spaceBetween="estateSpaceBetween"
            >
                <swiper-slide
                    v-for="(estate, index) in estates"
                    :key="estate.id"
                >
                    <a
                        :href="sanitize(estate.link)"
                        data-aos="fade-down"
                        :data-aos-delay="index * 300"
                        data-aos-once="true"
                        data-aos-duration="300"
                        class="tw-no-underline tw-grid lg:tw-grid-cols-1 tw-content-center tw-items-center tw-gap-x-3 tw-bg-white"
                    >
                        <div class="tw-p-4">
                            <h3
                                class="tw-text-[20px] tw-font-bold tw-text-darkGreen tw-mb-0 lg:tw-mb-2"
                            >
                                {{ estate.name }}
                            </h3>
                            <p
                                class="tw-font-regular tw-text-xs tw-text-darkGreen2 tw-uppercase tw-mb-0"
                            >
                                {{ estate.location }}
                            </p>
                        </div>

                        <figure class="tw-mb-0">
                            <picture>
                                <source
                                    media="(max-width: 991px)"
                                    :srcset="estate.mega_menu_thumbnail_image"
                                />
                                <source
                                    media="(min-width: 992px)"
                                    :srcset="estate.mega_menu_thumbnail_image"
                                />
                                <source
                                    media="(max-width: 991px)"
                                    :srcset="estate.mega_menu_thumbnail_image"
                                />
                                <img
                                    :srcset="estate.mega_menu_thumbnail_image"
                                    :alt="`${estate.name} thumbnail`"
                                    width=""
                                    height=""
                                    class="tw-aspect-[4/3] tw-w-full tw-h-full menu-estate__thumbnail"
                                />
                            </picture>
                        </figure>
                        <p
                            class="!tw-text-brown tw-text-xs tw-flex tw-content-center tw-items-center tw-gap-2.5 tw-justify-end tw-p-4 tw-mb-0"
                        >
                            LEARN MORE
                            <i
                                class="fa fa-chevron-right !tw-text-brown"
                                aria-hidden="true"
                            ></i>
                        </p>
                    </a>
                </swiper-slide>
            </swiper>
            <section
                v-else
                class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-5 tw-gap-4 lg:tw-gap-10"
            >
                <div
                    v-for="(estate, index) in estates"
                    :key="estate.id"
                    class="even:lg:tw-mt-10"
                >
                    <!-- {{ estate }} -->
                    <a
                        :href="sanitize(estate.link)"
                        class="tw-no-underline tw-grid tw-grid-cols-35pct-1fr-min lg:tw-grid-cols-1 tw-content-center tw-items-center tw-gap-x-3"
                        data-aos="fade-right"
                        :data-aos-delay="index * 300"
                        data-aos-once="true"
                        data-aos-duration="300"
                    >
                        <figure class="tw-mb-0 menu__estates-figure">
                            <picture>
                                <source
                                    media="(max-width: 991px)"
                                    :srcset="estate.mega_menu_thumbnail_image"
                                />
                                <source
                                    media="(min-width: 992px)"
                                    :srcset="estate.mega_menu_thumbnail_image"
                                />
                                <source
                                    media="(max-width: 991px)"
                                    :srcset="estate.mega_menu_thumbnail_image"
                                />
                                <img
                                    :srcset="estate.mega_menu_thumbnail_image"
                                    :alt="`${estate.name} thumbnail`"
                                    width=""
                                    height=""
                                    class="ratio ratio-1x1 tw-w-full tw-h-full menu-estate__thumbnail"
                                />
                            </picture>
                        </figure>
                        <div class="lg:tw-pt-8">
                            <h3
                                class="tw-text-[20px] lg:tw-text-2xl tw-font-bold tw-text-black tw-mb-0 lg:tw-mb-2 text-no-underline"
                            >
                                {{ estate.name }}
                            </h3>
                            <p
                                class="tw-font-medium tw-text-midGreen tw-uppercase tw-mb-0"
                            >
                                {{ estate.location }}
                            </p>
                        </div>
                        <i
                            class="d-lg-none fa fa-chevron-right tw-text-darkGreen"
                            aria-hidden="true"
                        ></i>
                    </a>
                </div>
            </section>
        </section>

        <TryQuickSearch />
    </section>
</template>

<style lang="scss">
.menu__estates-figure {
    height: 25vw;
}
@media only screen and (min-width: 992px) {
    .menu__estates-figure-desktop {
        height: 35vw;
    }
    .menu-estate__thumbnail {
        object-fit: cover;
        object-position: center;
    }
}
</style>
