<script>
import axios from 'axios';
import { height } from 'dom7';
import Gallery from './ImageGallery.vue';
import { assetURL } from '../utils/url';

export default {
    components: {
        Gallery,
    },
    data() {
        return {
            images: [],
            items: [],
            galleryID: 'propertiesGallery',
            imagesStatic: [
                {
                    url: '',
                    category: 'outdoor',
                    width: 1200,
                    height: 800,
                },
                {
                    url: `${assetURL}images/properties/gallery/2.jpg`,
                    category: 'outdoor',
                    width: 1200,
                    height: 800,
                },
                {
                    url: `${assetURL}images/properties/gallery/3.jpg`,
                    category: 'outdoor',
                    width: 1200,
                    height: 800,
                },
                {
                    url: `${assetURL}images/properties/gallery/4.jpg`,
                    category: 'outdoor',
                    width: 1200,
                    height: 800,
                },
                {
                    url: `${assetURL}images/properties/gallery/5.jpg`,
                    category: 'indoor',
                    width: 1200,
                    height: 800,
                },
                {
                    url: `${assetURL}images/properties/gallery/6.jpg`,
                    category: 'indoor',
                    width: 1200,
                    height: 800,
                },
                {
                    url: `${assetURL}images/properties/gallery/7.jpg`,
                    category: 'indoor',
                    width: 1200,
                    height: 800,
                },
                {
                    url: `${assetURL}images/properties/gallery/8.jpg`,
                    category: 'amenities',
                    width: 1200,
                    height: 800,
                },
                {
                    url: `${assetURL}images/properties/gallery/9.jpg`,
                    category: 'amenities',
                    width: 1200,
                    height: 800,
                },
            ],
            containerOffset: 'col-xl-11 offset-xl-1',
            slug: this.slug,
        };
    },
    computed: {
        slug() {
            const slug = new URL(location.href);
            return slug;
        },
    },
    methods: {
        getPropertyID(propertySlug) {
            axios
                .get(`/api/property/${propertySlug}`)
                .then((response) => {
                    this.$data.items = response.data.propertyID;
                    this.$data.images = response.data.gallery;
                })
                .catch((error) => {
                    console.log(error);
                    this.errored = true;
                });
        },
    },
    mounted() {
        const pathname = location.pathname.split('/');
        this.getPropertyID(pathname[2]);
    },
};
</script>

<template>
    <Gallery
        :galleryID="galleryID"
        :items="items"
        :images="images"
        :slug="slug"
        :imagesStatic="imagesStatic"
        :containerOffset="containerOffset"
    ></Gallery>
</template>
